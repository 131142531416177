import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Box from 'components/box';
import Head from 'components/head';

const Copyright = ({ data }) => (
  <>
    <Head pageTitle={data.copyrightsJson.title} />
    <Box>
      <div
        dangerouslySetInnerHTML={{
          __html: data.copyrightsJson.content.childMarkdownRemark.html,
        }}
      />
    </Box>
  </>
);

Copyright.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Copyright;

export const query = graphql`
  query CopyQuery {
    copyrightsJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
